.prodotti {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: auto;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  padding: 15px 0 40px 0;
  box-sizing: border-box;
}
.coontentprodotti {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  margin-top: 50px;
}
.container-select {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 400px;
  align-items: center;
  justify-content: center;
}
.benvenuto {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  margin: 20px auto;
  align-items: center;
  justify-content: center;
}
.logo{
  display: flex;
  flex-direction: row;
  width: 400px;
  height: auto;
  margin: 60px auto;
}


.riga{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;

}
.tipo_piatt{
  font-size: 14px;
  color: darkgray;
  text-decoration: dotted;
}
.nome_piatt{
  font-size: 12px;
  color: lightgray;
}
.ing_piatto_it{
  font-size: 11px;
  text-decoration: dotted;
}
.ing_piatto_en{
  font-size: 11px;
  text-decoration: dotted;
}

@media all and (max-width: 600px) {
  .prodotti {
    padding: 55px 10px 20px 10px;
  }
  .logo{
    width: 90%;
  }
}
