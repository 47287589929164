.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Aggiungi le tue modifiche specifiche per il componente CustomNavbar qui */
.navbar {
  background-color: #054e9bac ; /* Colore blu con !important */
  padding: 10px 0;
}

.nav-link {
  color: white ;
}

/* Navbar.css */
/* Navbar.css */
.custom-navbar {
  transition: background-color 0.3s ease-in-out; /* Aggiungi una transizione fluida */
}

.scrolled {
  background-color: white; /* Cambia il colore di sfondo a bianco quando scrollata */
}

.custom-caption {
  display: flex;
  align-items: center;
  right: 0;
  left: 0;
  height: 100%;

}
.sinistra{
  justify-content: left;

}
.destra{
  justify-content: end;

}
/* Aggiungi stile alla classe footer */
.footer {
  background-color: #007bff;
  color: white;
  padding: 20px 0;
  text-align: center;
}

/* Aggiungi stile al testo del footer */
.footer p {
  margin: 0;
}

/* Aggiungi stile agli elementi h4 nel footer */
.footer h4 {
  margin-bottom: 10px;
}
/* Home.css */

/* Home.css */

.latest-blogs {
  margin-top: 50px; /* Aggiungi spazio sopra la sezione degli ultimi blog */
}

.blog-list-container {
  overflow: hidden; /* Nascondi la barra di scorrimento orizzontale */
  margin-top: 20px; /* Aggiungi spazio sopra la lista dei blog */
  padding: 10px 0;
}

.blog-list-inner {
  display: flex;
  flex-wrap: nowrap;
  animation: scroll 20s linear infinite; /* Animazione di scorrimento */
}

.blog-item {
  flex: 0 0 auto;
  margin-right: 10px; /* Aggiungi margine tra gli elementi */
  min-width: 200px; /* Imposta la larghezza minima degli elementi */
}
.latest-blogs .blog-item {
  max-width: 100px; /* Imposta la larghezza massima */
  margin: 0 auto; /* Centra orizzontalmente */
  /* Aggiungi ulteriori stili personalizzati secondo le tue preferenze */
}
/* Stili per le frecce di scorrimento laterale (opzionale) */
.scroll-arrow {
  font-size: 24px;
  cursor: pointer;
  margin: 0 10px;
}
.blog-item {
  max-width: 150px;
  margin: 0 10px;
  
}
.blog-item-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
   /* Assicurati che l'altezza sia al 100% per centrare verticalmente */
}
/* Aggiungi questa classe per ridurre le dimensioni delle immagini all'interno degli articoli */
.blog-item img {
  max-width: 100%;
  height: auto;
}
.cardmargin{
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}


/* Animazione di scorrimento */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 992px) {
  .ml-auto {
    margin-left: auto !important;
    margin-right: 0;
  }
}
